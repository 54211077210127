import { Box, Typography, MenuItem, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import "./style.css";
import PricingDeleteModal from "../../components/PricingDeleteModal";
import { getAllPricing } from "../../apis/pricing";
import { getAllPricings } from "../../actions/pricingAction";

const Pricing = () => {
  const { indices } = useSelector((state: any) => state.pricingReducer);
  const dispatch = useDispatch();

  const [openDeletePricingModal, setOpenDeletePricingModal] = useState(false);
  const [pricingDetails, setPricingDetails] = useState(indices);
  const [filteredPricingDetails, setFilteredPricingDetails] = useState(indices);
  const [pricingData, setPricingData] = useState();
  const [country, setCountry] = useState(1);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToEdit = (id: number, carType: string) => {
    navigate(`/pricing/edit/${id}?type=${carType}`);
  };

  const handleDeletePricingModal = () => {
    setOpenDeletePricingModal(true);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event?.target?.value ? Number(event?.target?.value) : 1);
    const region: any = countries.find((item: any) => item.id === event?.target?.value);
    const filteredData = pricingDetails.filter((item: any) => item.country_code === region?.value);

    if (filteredData) setFilteredPricingDetails(filteredData);
    else setFilteredPricingDetails(pricingDetails);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getAllPricing();
    dispatch(getAllPricings(data));
    setPricingDetails(data);
    getCountries(data);
    setLoading(false);
  };

  const removeDuplicatesByValue = (items: any) => {
    const uniqueValuesMap: any = {};
    return items.filter((item: any) => {
      if (uniqueValuesMap[item.value]) {
        return false;
      }
      uniqueValuesMap[item.value] = true;
      return true;
    });
  };

  const getCountries = (pricingData: any) => {
    if (pricingData.length > 0) {
      let id = 1;
      let regionData = pricingData.map((detail: any) => ({
        id: id++,
        objId: detail._id,
        value: detail.country_code,
      }));

      regionData = removeDuplicatesByValue(regionData);

      const filtregion: any = regionData.find((item: any) => item.id === country);
      const filteredData = pricingData.filter(
        (item: any) => item.country_code === filtregion.value
      );

      if (filteredData) setFilteredPricingDetails(filteredData);
      else setFilteredPricingDetails(pricingDetails);
      setCountries(regionData);
    }
  };

  const navigateToCreate = () => {
    navigate("/pricing/create");
  };

  const currency = (region: string) => {
    const currencyData: Record<string, string> = {
      abidjan: "FCFA",
      "london-boroughs": "£",
      "ile-de-france": "€",
    };
    return currencyData[region];
  };

  useEffect(() => {
    //fetch all the pricing data
    getData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                  Pricing Management
                </Typography>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "end",
                      marginBottom: "20px",
                    }}
                  >
                    <Button variant="contained" onClick={navigateToCreate}>
                      Create Pricing
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: "5px", backgroundColor: "#FF797D" }}
                    >
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDeletePricingModal();
                        }}
                      />
                    </Button>
                  </div>
                </Box>

                {/* Delete Pricing Modal */}
                {openDeletePricingModal && (
                  <PricingDeleteModal
                    pricingIds={[
                      filteredPricingDetails[0]._id,
                      filteredPricingDetails.length > 1 && filteredPricingDetails[1]._id,
                    ]}
                    openModal={openDeletePricingModal}
                    setOpenModal={setOpenDeletePricingModal}
                    pricingDetails={pricingDetails}
                    setPricingData={setPricingData}
                    fetchUpdatedData={getData}
                  />
                )}
              </div>
              <Box style={{ marginBottom: "50px" }}>
                <Typography>Country</Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  select
                  name="country"
                  variant="outlined"
                  style={{ marginBottom: "10px", width: "100%", textTransform: "capitalize" }}
                  value={country}
                  defaultValue=""
                  onChange={onHandleChange}
                >
                  {countries.map((option: any) => (
                    <MenuItem key={option.id} value={option.id} sx={{ textTransform: "uppercase" }}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ marginBottom: 7 }}>
                <Typography sx={{ marginBottom: "10px", fontWeight: 500, fontSize: 20 }}>
                  Normal User Pricing
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                      <TableRow>
                        <TableCell align="center" className="cellContainer">
                          Car Type
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Region
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Price per KM
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Price per Min
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Min Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Base Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Late Price per Min
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Late Penalty
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Share Ride Multiplier
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Share Ride Min Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Toll Charge
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPricingDetails.length > 0 &&
                        filteredPricingDetails.map((detail: any, index: number) => {
                          return (
                            <>
                              {detail.user_type === "user" &&
                                detail.price_list.map((indiceRow: any, indiceIndex: number) => {
                                  return (
                                    <TableRow
                                      key={indiceIndex + 1}
                                      sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        cursor: "pointer",
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {detail.region_code === "abidjan" &&
                                        indiceRow.car_type === "GREEN"
                                          ? "COMFORT"
                                          : indiceRow.car_type}
                                      </TableCell>
                                      <TableCell align="center">{detail.region_code}</TableCell>
                                      <TableCell align="center">
                                        {indiceRow.price_per_km} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.price_per_min} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.min_price} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.base_price} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.late_price.price_per_minute}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.late_price.penality}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.shared_ride_multiplier}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.shared_ride_min_price}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.toll_charge} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        <EditIcon
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            navigateToEdit(detail._id, indiceRow.car_type);
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <Typography sx={{ marginBottom: "10px", fontWeight: 500, fontSize: 20 }}>
                  Pro User Pricing
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                      <TableRow>
                        <TableCell align="center" className="cellContainer">
                          Car Type
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Region
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Price per KM
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Price per Min
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Min Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Base Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Late Price per Min
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Late Penalty
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Share Ride Multiplier
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Share Ride Min Price
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Toll Charge
                        </TableCell>
                        <TableCell align="center" className="cellContainer">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPricingDetails.length > 0 &&
                        filteredPricingDetails.map((detail: any, index: number) => {
                          return (
                            <>
                              {detail.user_type === "pro-user" &&
                                detail.price_list.map((indiceRow: any, indiceIndex: number) => {
                                  return (
                                    <TableRow
                                      key={indiceIndex + 1}
                                      sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        cursor: "pointer",
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {detail.region_code === "abidjan" &&
                                        indiceRow.car_type === "GREEN"
                                          ? "COMFORT"
                                          : indiceRow.car_type}
                                      </TableCell>
                                      <TableCell align="center">{detail.region_code}</TableCell>
                                      <TableCell align="center">
                                        {indiceRow.price_per_km} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.price_per_min} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.min_price} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.base_price} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.late_price.price_per_minute}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.late_price.penality}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.shared_ride_multiplier}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.shared_ride_min_price}{" "}
                                        {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        {indiceRow.toll_charge} {currency(detail.region_code)}
                                      </TableCell>
                                      <TableCell align="center">
                                        <EditIcon
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            navigateToEdit(detail._id, indiceRow.car_type);
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;
