import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { jwtDecode } from "jwt-decode";

const settings = ["Logout"];
const TopBar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  // Checking if a user is logged in by retrieving the token from local storage
  const token = localStorage.getItem("token");
  const [decodedToken, setDecodedToken] = useState<any>(token && jwtDecode(token));
  console.log("decodedtoken", decodedToken);
  const name = decodedToken && decodedToken.role;

  // useEffect hook (currently empty)
  useEffect(() => {
    // Add any side effects or initializations if needed
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);
  //methods
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleMenuItemClick = (
  //   event: React.MouseEvent<HTMLElement>,
  //   index: number,
  //   value: string
  // ) => {
  //   if (index === 1) {
  //     logout();
  //   }
  //   setAnchorElUser(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <AppBar position="fixed" style={{ color: "#1677BC", backgroundColor: "white" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Typography alignContent={"center"}>
              {`${decodedToken.firstName}  ${decodedToken.lastName}`}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenUserMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout}>
                <Typography
                  textAlign="center"
                  style={{ paddingLeft: "15px", paddingRight: "15px" }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
